import { Route, Redirect, Switch } from "react-router-dom";

import React, { Suspense, lazy, Fragment } from "react";
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer } from "react-toastify";
import AdminRoute from "../../utils/AdminRoute";
import { useAuth } from "../../context/auth";

const Settings = lazy(() => import("../../DemoPages/Settings"));
const Home = lazy(() => import("../../DemoPages/Home"));
const Login = lazy(() => import("../../DemoPages/Login"));
const Signup = lazy(() => import("../../DemoPages/Signup"));
const Outreach = lazy(() => import("../../DemoPages/Outreach"));
const Telegram = lazy(() => import("../../DemoPages/Telegram"));
const Knowledge = lazy(() => import("../../DemoPages/Knowledge"));
const EmailApp = lazy(() => import("../../DemoPages/EmailApp"));
const Bot = lazy(() => import("../../DemoPages/Bot"));
const BotStatus = lazy(() => import("../../DemoPages/BotStatus"));
const BotList = lazy(() => import("../../DemoPages/BotList"));
const BotWidget = lazy(() => import("../../DemoPages/BotWidget"));
const resetPassword = lazy(() => import("../../DemoPages/ResetPassword"));
const EditBot = lazy(() => import("../../DemoPages/Bot/EditBot"));
const Account = lazy(() => import("../../DemoPages/Account"));
const Plans = lazy(() => import("../../DemoPages/Plans"));
const Campaign = lazy(() => import("../../DemoPages/Campaign"));

const NotFound = lazy(() => import("../../DemoPages/NotFound"));
const Success = lazy(() => import("../../DemoPages/Success"));
const Accordian = lazy(() => import("../../DemoPages/Accordian"));
const Dashboard = lazy(() => import("../../AdminPages/AdminHome"));
const ClientList = lazy(() => import("../../AdminPages/ClientList"));
const ViewBotStatus = lazy(() => import("../../AdminPages/ViewClient"));
const ClientSettings = lazy(() => import("../../AdminPages/ClientSettings"));
const ResendConfirmationEmail = lazy(() =>
  import("../../DemoPages/Login/ResendConfirmationEmail")
);
const CreateClientBot = lazy(() => import("../../AdminPages/CreateClientBot"));
const EditClientbot = lazy(() => import("../../AdminPages/EditClientbot"));

const AppMain = () => {
  const auth = useAuth();

  const onBotAdded = (data) => {
    if (data.length != 0) {
    }
  };
  return (
    <Fragment>
      {/* Components */}

      <Suspense
        fallback={
          <div className="loader-container">
            <div className="loader-container-inner">
              <h6 className="mt-5">
                <div className="loader-wrapper d-flex justify-content-center align-items-center">
                  <div className="loader loader-undefined loader-active">
                    <div className="loader-inner ball-scale-multiple">
                      <div></div>
                      <div></div>
                      <div></div>
                    </div>
                  </div>
                </div>
              </h6>
            </div>
          </div>
        }
      >
        <Switch>
          <Route path="/login" component={Login} />
          <Route path="/signup" component={Signup} />
          <Route path="/home" component={Home} />
          <Route path="/bot" component={Bot} onBotAdded={onBotAdded} />
          <Route path="/botlist" component={BotList} />
          <Route path="/botstatus/:id" component={BotStatus} />
          <Route path="/telegram" component={Telegram} />
          <Route path="/outreach" component={Outreach} />
          <Route path="/settings" component={Settings} />
          <Route path="/knowledge" component={Knowledge} />
          <Route path="/emailapp" component={EmailApp} />
          <Route path="/reset-password" component={resetPassword} />
          <Route path="/editbot/:id" component={EditBot} />
          <Route path="/account" component={Account} />
          <Route path="/plans" component={Plans} />
          <Route path="/campaign" component={Campaign} />
          <Route path="/success" component={Success} />
          <Route path="/accordian" component={Accordian} />
          <Route path="/widget" component={BotWidget} />
          <Route
            path="/resend-confirmation-email"
            component={ResendConfirmationEmail}
          />
          <AdminRoute
            path="/dashboard"
            component={Dashboard}
            auth={auth?.user?.isAdmin}
          />
          <AdminRoute
            path="/clientlist"
            component={ClientList}
            auth={auth?.user?.isAdmin}
          />
          <AdminRoute
            path="/clientsettings/:id"
            component={ClientSettings}
            auth={auth?.user?.isAdmin}
          />
          <AdminRoute
            path="/viewclient/:id"
            component={ViewBotStatus}
            auth={auth?.user?.isAdmin}
          />
          <AdminRoute
            path="/editclientbot/:id"
            component={EditClientbot}
            auth={auth?.user?.isAdmin}
          />
          <AdminRoute
            path="/createclientbot"
            component={CreateClientBot}
            auth={auth?.user?.isAdmin}
          />
          <Route component={NotFound} />
        </Switch>
      </Suspense>

      <Route exact path="/" render={() => <Redirect to="/login" />} />
      <ToastContainer />
    </Fragment>
  );
};

export default AppMain;
