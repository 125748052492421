import React from 'react';
import { Route, Redirect } from 'react-router-dom';

const AdminRoute = ({ component: Component, auth, ...rest }) => (
  <Route
    {...rest}
    render={(props) =>
      auth ? (
        <Component {...props} />
      ) : (
        <Redirect to="/home" />
      )
    }
  />
);

export default AdminRoute;
